import React from "react";
import omit from "lodash/omit";
import { connect } from "react-redux";
import withTranslation from "../hocs/withTranslation";
import FindLocationView from "../components/FindLocationView";
import {
  getServingOptionDetails,
  getAvailableLocations,
  getBranchesAvailability,
  deliveryOptionsFilteredByAvailability,
  shouldHideWarningMessageForServingOption,
  getAvailableServingOptions,
  getStyles,
} from "../store/selectors";
import {
  loadBranchAvailabiltyIfNeeded,
  loadDeliveryAreas,
} from "../store/locations/actions";
import {
  enableAutoGeoLocation,
  disableAutoGeoLocation,
} from "../store/user/actions";
import {
  setServingOption,
  setUserSelectedLocationAndServingOption,
  setSearchCode,
  setTableCode,
  resetTableCode,
} from "../store/order/actions";

import "../components/Tabs/index.scss";

const FindLocationPage = (props) => <FindLocationView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    app: { isSSR, google },
    locations,
    user,
    order,
    giftRedeem,
  } = state;

  return {
    google,
    isSSR,
    user,
    order,
    giftRedeem,
    appStyles: getStyles(state, props),
    locations: omit(
      getAvailableLocations(state, props),
      "branchesAvailability",
    ),
    servingOptionDetails: getServingOptionDetails(state, props),
    branchesAvailability: getBranchesAvailability(state, props),
    deliveryOptions: deliveryOptionsFilteredByAvailability(state, props),
    availableServingOptions: getAvailableServingOptions(state, props),
    shouldHideWarningMessageForServingOption: shouldHideWarningMessageForServingOption(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDeliveryAreas: (params) => dispatch(loadDeliveryAreas(params)),
    loadBranchAvailabiltyIfNeeded: () =>
      dispatch(loadBranchAvailabiltyIfNeeded()),
    setOrderServingOption: (servingOption) =>
      dispatch(setServingOption(servingOption)),
    enableAutoGeoLocation: () => dispatch(enableAutoGeoLocation()),
    disableAutoGeoLocation: () => dispatch(disableAutoGeoLocation()),
    setUserSelectedLocationAndServingOption: ({
      servingOption,
      locationId,
      firstAvailableDate,
      userAddress,
      defaultTip,
    }) =>
      dispatch(
        setUserSelectedLocationAndServingOption({
          servingOption,
          locationId,
          firstAvailableDate,
          userAddress,
          defaultTip,
          props,
        }),
      ),
    setSearchCode: (searchCode) => dispatch(setSearchCode(searchCode)),
    setTableCode: (tableCode) => dispatch(setTableCode(tableCode)),
    resetTableCode: (tableCode) => dispatch(resetTableCode()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(FindLocationPage));
