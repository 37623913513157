// extracted by mini-css-extract-plugin
export var AddressInputContainer = "index-module--AddressInputContainer--94c94";
export var AddressLabel = "index-module--AddressLabel--ff22e";
export var AddressList = "index-module--AddressList--cd421";
export var AddressListItem = "index-module--AddressListItem--358b3";
export var AddressListItemContent = "index-module--AddressListItemContent--7b561";
export var AvailabilityPanel = "index-module--AvailabilityPanel--6cc10";
export var AvailabilityPanelWarning = "index-module--AvailabilityPanelWarning--690f8";
export var BigDeliveryIcon = "index-module--BigDeliveryIcon--c775b";
export var BranchesList = "index-module--BranchesList--147a1";
export var CancelButton = "index-module--CancelButton--d9fb3";
export var CashIcon = "index-module--CashIcon--dee3b";
export var Content = "index-module--Content--811fc";
export var DeliveryIcon = "index-module--DeliveryIcon--78a91";
export var Distance = "index-module--Distance--95d71";
export var DistanceToBranch = "index-module--DistanceToBranch--42fe9";
export var DropOffIcon = "index-module--DropOffIcon--bc496";
export var EmptyBagIcon = "index-module--EmptyBagIcon--ee9c4";
export var ErrorMessage = "index-module--ErrorMessage--70208";
export var GiftsHeader = "index-module--GiftsHeader--5fced";
export var GoogleLogo = "index-module--GoogleLogo--bdd65";
export var HouseNumberInput = "index-module--HouseNumberInput--d1c6a";
export var HouseNumberInputWrapper = "index-module--HouseNumberInputWrapper--919fc";
export var ListItem = "index-module--ListItem--e6e55";
export var ListItemContent = "index-module--ListItemContent--c8821";
export var LocateMeButton = "index-module--LocateMeButton--0b973";
export var LocationPinIcon = "index-module--LocationPinIcon--cddf4";
export var RTL = "index-module--RTL--f1e55";
export var SearchButton = "index-module--SearchButton--a60c4";
export var ServingOptions = "index-module--ServingOptions--aaed0";
export var VerticalLine = "index-module--VerticalLine--1551a";
export var WithGiftInstruction = "index-module--WithGiftInstruction--30669";
export var fadeAndGrowInDown = "index-module--fadeAndGrowInDown--8a6f3";
export var fadeAndGrowInUp = "index-module--fadeAndGrowInUp--d76d7";