import React from "react";
import debounceRender from "react-debounce-render";
import { Fade, CircularProgress } from "@mui/material";

const debouncedCircularProgress = ({ show = false, size = 22 }) => {
  return (
    <Fade in={show} unmountOnExit>
      <CircularProgress style={{ height: size, width: size }} />
    </Fade>
  );
};

export default debounceRender(debouncedCircularProgress, 100);
