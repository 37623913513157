import React from "react";
import CashIcon from "../icons/Cash.svg";
import LocationPinIcon from "../icons/LocationPin.svg";
import SelectArrowIcon from "../icons/SelectArrow.svg";
import Image from "../image";
import List from "../List";
import AvailabilityPanel from "./AvailabilityPanel";
import * as styles from "./index.module.scss";

import { mediaTypes } from "../../utils/media";

const BranchListItem = ({
  T,
  appStyles = {},
  showAddress,
  onClick,
  to,
  showOrderLater,
  availableFrom,
  closedMessage,
  disableOrder,
  minimumOrderAmount,
  currencySymbol,
  metric,
  onBranchLocationClick,
  branch,
  servingOptionTag,
  servingOptionType,
  hasTableCode,
}) => {
  const {
    actionColor,
    accentColor,
    BranchListItem: {
      backgroundColor,
      fontSize,
      fontFamily,
      textTransform,
    } = {},
    rtl,
  } = appStyles;

  const {
    id,
    timeFrameError,
    imageKey,
    imagePreview,
    name,
    address,
    openHours,
    phoneNumber,
    distanceToBranch,
    deliveryTimeDuration,
    price,
    freeDeliveryOrderAmount,
    geoPoint,
    timeZoneStr,
  } = branch;

  const distanceToBranchInMetrics = metric
    ? (distanceToBranch / 1000).toFixed(1)
    : (distanceToBranch / 1609.34).toFixed(1);

  return (
    <List.Card
      style={{ flexDirection: "column", overflow: "hidden" }}
      appStyles={appStyles}
      key={id}
      hasErrors={timeFrameError || showOrderLater || disableOrder}
      onClick={onClick}
      to={showOrderLater || disableOrder || hasTableCode ? undefined : to}
    >
      {imageKey && (
        <Image
          imagePreview={imagePreview}
          mediaType={mediaTypes.branch}
          mediaKey={imageKey}
          sizes="300px"
          wrapperStyle={{
            height: appStyles.branchImageHeight || "200px",
            width: "100%",
          }}
          imgStyle={
            appStyles.branchImageFitMode && {
              objectFit: appStyles.branchImageFitMode,
            }
          }
        />
      )}
      <div style={{ display: "flex", backgroundColor }}>
        <List.Card.Content style={{ paddingRight: 10 }}>
          <List.Card.Title appStyles={appStyles}>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <span style={{ fontSize, fontFamily, textTransform }}>
                {name}
              </span>
              {distanceToBranch ? (
                <div
                  className={styles.Distance}
                  style={{ textAlign: rtl ? "left" : "right" }}
                  onClick={onBranchLocationClick(geoPoint)}
                >
                  <LocationPinIcon
                    className={styles.LocationPinIcon}
                    style={{
                      ...(rtl ? { marginLeft: 4 } : { marginRight: 4 }),
                      color: appStyles.desktopLocateButtonColor,
                    }}
                  />
                  {distanceToBranchInMetrics}{" "}
                  <span
                    style={{ textTransform, ...(rtl && { marginRight: 4 }) }}
                  >
                    {metric ? T("km") : T("mi")}
                  </span>
                </div>
              ) : (
                _.isNumber(minimumOrderAmount) && (
                  <small
                    style={{
                      fontSize: "0.75rem",
                      textAlign: rtl ? "left" : "right",
                    }}
                    className={styles.CashIcon}
                  >
                    <CashIcon style={{ color: appStyles.accentColor }} />
                    <span
                      style={{ textTransform, color: appStyles.accentColor }}
                    >
                      {`${T(
                        "Minimum Order:",
                      )} ${currencySymbol}${minimumOrderAmount.toFixed(2)}`}
                    </span>
                  </small>
                )
              )}
            </span>
          </List.Card.Title>
          {showAddress && address && (
            <div style={{ textTransform }}>{T(address)}</div>
          )}

          {phoneNumber && <span>{phoneNumber}</span>}
          {deliveryTimeDuration && (
            <span>
              {T("Delivery Time")}: {deliveryTimeDuration}
            </span>
          )}
          {price && (
            <span>
              {`${T("Delivery Fee")}: ${price} ${T("{{currency_symbol}}")} `}
              {freeDeliveryOrderAmount &&
                `(${T("Free for orders over")} ${freeDeliveryOrderAmount})`}
            </span>
          )}
          <AvailabilityPanel
            T={T}
            appStyles={appStyles}
            to={hasTableCode ? undefined : to}
            showOrderLater={showOrderLater}
            disableOrder={disableOrder}
            availableFrom={availableFrom}
            timeZoneStr={timeZoneStr}
            servingOptionTag={servingOptionTag}
            servingOptionType={servingOptionType}
            closedMessage={closedMessage}
          />
          {showAddress && openHours && (
            <div style={{ textTransform, marginTop: 5 }}>
              <small>{openHours}</small>
            </div>
          )}
        </List.Card.Content>
        {!(showOrderLater || disableOrder || hasTableCode) && (
          <List.Item.Actions color={actionColor} rtl={rtl}>
            <SelectArrowIcon />
          </List.Item.Actions>
        )}
      </div>
    </List.Card>
  );
};

export default BranchListItem;
