import { omitBy, isNil } from "lodash";

export const search = async (
  endpoint,
  source,
  accessToken,
  query,
  onResult = (err, res, searchTime) => {},
  proximity, //{ longitude: number, latitude: number }
  country,
  bbox, //[[][]]
  types,
  limit,
  autocomplete,
  language,
) => {
  const searchTime = new Date();
  try {
    const baseUrl = `${endpoint}/geocoding/v5/${source}/${query}.json`;
    // Don't send empty query params to Mapbox geocoding api.
    const searchParams = omitBy(
      {
        access_token: accessToken,
        proximity:
          proximity && Object.keys(proximity).length === 2
            ? `${proximity.longitude},${proximity.latitude}`
            : null,
        bbox: bbox && bbox.length > 0 ? bbox.join(",") : null,
        types,
        country,
        limit,
        autocomplete,
        language,
      },
      isNil,
    );
    const url = `${baseUrl}?${toUrlString(searchParams)}`;
    const res = await fetch(url);
    const data = await res.json();
    onResult(null, data, searchTime);
    return { err: null, res, searchTime };
  } catch (err) {
    onResult(err, null, searchTime);
    return { err, res: null, searchTime };
  }
};

function toUrlString(params) {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]),
    )
    .join("&");
}
