import classnames from "classnames";
import React from "react";
import getLocationClosedText from "../../utils/getLocationClosedText";
import Button from "../Button";
import ClockIcon from "../icons/Clock.svg";
import List from "../List";
import * as styles from "./index.module.scss";

export default ({
  T,
  appStyles,
  to,
  showOrderLater,
  disableOrder,
  availableFrom,
  timeZoneStr,
  servingOptionTag,
  servingOptionType,
  closedMessage,
}) => {
  return showOrderLater ? (
    <List.Card.WarningPanel rtl={appStyles.rtl} appStyles={appStyles}>
      <span
        className={classnames(
          styles.AvailabilityPanel,
          styles.AvailabilityPanelWarning,
          appStyles.rtl && styles.RTL,
        )}
      >
        <ClockIcon />
        {getLocationClosedText({
          T,
          availableFrom,
          servingOptionTag,
          locale: appStyles.locale,
          timeZoneStr,
          servingOptionType,
          closedMessage,
        })}
      </span>
      {!disableOrder && to && (
        <Button slim centered marginTop appStyles={appStyles} to={to}>
          {T("Order for later")}
        </Button>
      )}
    </List.Card.WarningPanel>
  ) : disableOrder ? (
    <List.Card.ErrorPanel rtl={appStyles.rtl} appStyles={appStyles}>
      <span className={styles.AvailabilityPanel}>
        <ClockIcon />
        {getLocationClosedText({
          T,
          availableFrom,
          servingOptionTag,
          locale: appStyles.locale,
          timeZoneStr,
          servingOptionType,
          closedMessage,
        })}
      </span>
    </List.Card.ErrorPanel>
  ) : (
    <List.Item.SuccessPanel rtl={appStyles.rtl} appStyles={appStyles}>
      <span>
        <ClockIcon /> {T("Open")}
      </span>
    </List.Item.SuccessPanel>
  );
};
