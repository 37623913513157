import React from "react";
import debounceRender from "react-debounce-render";
import { Fade, LinearProgress } from "@mui/material";

const debouncedProgressBar = ({ show = false }) => {
  return (
    <Fade in={show}>
      <LinearProgress style={{ position: "absolute", width: "100%" }} />
    </Fade>
  );
};

export default debounceRender(debouncedProgressBar, 100);
